@import url('./shared/styles/common.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-1uoebqq-MuiPaper-root-MuiDialog-paper {
 width: fit-content;
}

.css-j3g793-MuiCalendarOrClockPicker-root, .MuiCalendarOrClockPicker-root, .css-1xhj18k{
 flex-direction: column !important;
}

.css-wt6ke5  {
 flex-direction: column !important;
 flex-flow: no-wrap  !important
}
.css-1r3tc0d-MuiTimePickerToolbar-hourMinuteLabel, .css-1p0zo9q {
  margin: auto !important;
}

.css-1bt0dft {
  flex-direction: row !important;
}

.css-1m5r8g7-MuiTimePickerToolbar-amPmSelection, .css-evwu3f {
  flex-basis: unset !important;
  -webkit-flex-basis: unset !important;
  margin: auto !important;
}

.css-1d2dhb4-MuiButtonBase-root-MuiButton-root-MuiPickersToolbarButton-root, .css-1pyzne9{
  border: 1px solid #CACACA !important;
  padding: 0px 4px !important;
}