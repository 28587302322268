@font-face {
    font-family: 'Open Sans';
    /* src: url('../assets/fonts/open-sans/static/OpenSans/OpenSans-Regular.ttf') format('truetype'); */
    src: url('../assets/fonts/open-sans/static/OpenSans/OpenSans-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'Rastella';
    src: url('../assets/fonts/rastella/Rastella.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Norwester';
    src: url('../assets/fonts/norwester/norwester.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

body {
    overflow-y: scroll;
}

* {
    font-family: 'Open Sans';
    text-decoration: none;
}